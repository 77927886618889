import { SortConfig, Stav } from "./types/Types";

/**
 * Vrátí kořenovou URL serveru na základě aktuálního prostředí (vývojovou či produkční).
 * 
 * @returns kořenová URL serveru
 */
export const getBaseUrl = (): string => {
    if (process.env.PUBLIC_URL) {
        return process.env.PUBLIC_URL;
    }
    return 'http://127.0.0.1:3001';
}

const TOKEN_KEY = "token";

/**
 * Uloží token do local storage prohlížeče.
 * 
 * @param token token
 */
export const storeToken = (token: string) => {
    localStorage.setItem(TOKEN_KEY, token);
}

/**
 * Vrátí token z local storage, pokud tam je.
 * 
 * @returns token nebo null
 */
export const getToken = (): string | null => {
    return localStorage.getItem(TOKEN_KEY);
}

/**
 * Odstraní token z local storage, pokud tam je.
 */
export const deleteToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const getStavKey = (stav: Stav) => {
    const indexOfS = Object.values(Stav).indexOf(stav as unknown as Stav);
    return Object.keys(Stav)[indexOfS];
}

/**
 * Převede "technický" řetězec (ve formátu YYYY-MM-DD) na datum.
 *
 * @param str vstupní řetězec ve formátu YYYY-MM-DD
 * @returns datum nebo undefined, pokud se nejedná o platné datum
 */
export const stringToDate = (str?: string | null) => {
    if (!str) {
        return;
    }
    const tmp = str.split("-");
    const date = new Date(parseInt(tmp[0]), parseInt(tmp[1]) - 1, parseInt(tmp[2]));
    return isNaN(date.getTime()) ? undefined : date;
}

/**
 * Převede datum na "uživatelský" řetězec pro zobrazení (např. 1.1.2024).
 *
 * @param date vstupní datum
 * @returns výsledný řetězec
 */
export const dateToHumanString = (date?: Date | null) => {
    if (!date) {
        return;
    }
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

/**
 * Převede datum na "technický" řetězec, vhodný pro ukládání a řazení.
 * 
 * @param date vstupní datum
 * @returns řetězec ve formátu 2024-01-01
 */
export const dateToString = (date: Date) => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : (date.getMonth() + 1);
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

/**
 * Vrátí věk uživatele na základě data narození.
 * 
 * @param dateOfBirth datum narození
 * @returns věk v letech nebo null
 */
export const calculateAge = (dateOfBirth?: Date) => {
    if (!dateOfBirth) {
        return;
    }
    return Math.abs(new Date(Date.now() - dateOfBirth.getTime()).getUTCFullYear() - 1970);
}

/**
 * Funkce vracející vstupní data zafiltrovaná a/nebo seřazená dle předaného filtru.
 * 
 * @param data pole dat k filtraci / řazení
 * @param searchValue hledaný výraz
 * @param searchKeys klíče (atributy) dat, dle kterých filtrovat
 * @param sortConfig konfigurace řazení
 * @returns 
 */
export function filterSort<Type>(data: Type[] | undefined, searchValue: string | undefined, searchKeys: (keyof Type)[] | undefined, sortConfig?: SortConfig<Type> | undefined) {
    let filtered = data;
    if (data) {
        if (searchValue && searchValue !== "" && searchKeys != null) {
            filtered = data.filter(obj => {
                for (const t of searchKeys) {
                    if (typeof obj[t] === 'string') {
                        const attrValue = obj[t] as string;
                        if (attrValue.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }
        if (sortConfig && sortConfig.key) {
            filtered?.sort((a, b) => {
                // TODO nevím co s tím
                //@ts-ignore
                return sortConfig.direction === 'ascending' ? a[sortConfig.key].localeCompare(b[sortConfig.key], 'cz', { numeric: true, sensitivity: 'base' }) : b[sortConfig.key].localeCompare(a[sortConfig.key], 'cz', { numeric: true, sensitivity: 'base' });
            })
        }
    }
    return filtered;
};

/**
 * Vrátí className pro hlavičku tabulky umožňující řazení.
 * 
 * @param sortConfig aktuální konfigurace řazení
 * @param key název atributu objektu, který hlavička představuje
 * @returns hodnota pro className
 */
export function getSortableHeaderClassName<Type>(sortConfig: SortConfig<Type> | undefined, key: keyof Type) {
    if (sortConfig?.key === key) {
        return `sortable ${sortConfig.direction}`;
    }
    return 'sortable';
}