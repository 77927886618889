import { toast } from "react-toastify";
import { getBaseUrl, getToken } from "../Utils";

async function request<TResponse>(
    url: string,
    config: RequestInit = {}
): Promise<TResponse> {
    config.headers = config?.headers ? new Headers(config.headers) : new Headers();
    config.headers.set("Authorization", `Bearer ${getToken()}`);
    return fetch(getBaseUrl() + url, config).then(response => {
        const data = response.json();
        if (!response.ok) {
            return data.then(value => {
                throw new Error(value);
            })
        }
        return data as TResponse;
    });
}

export const api = {
    get: <TResponse>(url: string) => request<TResponse>(url),
    delete: <TResponse>(url: string) => request<TResponse>(url, { method: 'DELETE' }),
    post: <TBody extends BodyInit, TResponse>(url: string, body: TBody) => request<TResponse>(url, { method: 'POST', body, headers: { 'Content-Type': 'application/json' } }),
}

export const apiCall = async (call: Function, ...args: any[]) => {
    try {
        return await call(...args);
    } catch (e: any) {
        toast.error((e as Error).message, { theme: "colored" });
        throw Error('Chyba při volání API');
    }
}