import { Klient, KlientNotifikace, KlientVysledekTestBalance, KlientVysledekTestHba1c, KlientVysledekTestVitaminD, Produkt } from './DatabaseTypes';

// TODO zjistit kvůli čemu jsme to vůbec museli rozšířit, nejde tam náhodou dát specifické klíče?
export interface ProduktForm extends Produkt {
    [key: string]: string | number | null | undefined;
}

// VO klienta pro UI, rozšířené o notifikace a výsledky testů
export interface KlientForm extends Klient {
    notifikace?: KlientNotifikace[],
    vysledkyTestBalance?: KlientVysledekTestBalance[],
    vysledkyTestVitaminD?: KlientVysledekTestVitaminD[],
    vysledkyTestHba1c?: KlientVysledekTestHba1c[],
}

// Výčet typů notifikací
export enum TypNotifikace {
    PREDPLATNE = 'Předplatné',
    PLATBA_PREDEM = 'Platba předem 8+1',
    ZE_SKLADU = 'Ode mne ze skladu',
    JEDNORAZOVY_NAKUP = 'Jednorázový nákup',
}

// Výčet typů přerušení notifikace
export enum TypPreruseni {
    TRICET = '30',
    SEDESAT = '60',
    DEVADESAT = '90',
    ZRUSENO = 'Zrušeno',
}

// Výčet stavů notifikace klienta
export enum Stav {
    NEHOTOVO = 'Nehotovo',
    ROZPRACOVANO = 'Rozpracováno',
    HOTOVO = 'Hotovo',
}

// VO jedné položky ve výpisu notifikací (na hlavní obrazovce)
export interface KlientProduktNotifikaceVO {
    'idKlient'?: number | null;
    'termin'?: string | null;
    'kodNotifikace'?: string | null;
    'stavNotifikace'?: keyof typeof Stav | null;
    'prijmeni'?: string | null;
    'jmeno'?: string | null;
    'zakaznickeCislo'?: string | null;
    'produkt'?: string | null;
}

// Konfigurace řazení pro libovolné VO
export interface SortConfig<Type> {
    key: keyof Type; // Název atributu objektu, dle kterého řadit
    direction?: "ascending" | "descending"; // Směr řazení (vzestupně, sestupně)
}

// Konstanta pro "fiktivní" notifikaci narozenin. Reálně v databázi neexistuje, je virtuální.
export const NOTIFICATION_BIRTHDAY_KEY = "NAROZENINY";