import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { KlientForm, Stav, TypNotifikace, TypPreruseni } from "../types/Types";
import React, { useMemo, useState } from "react";
import { KlientNotifikace, KlientVysledekTestBalance, KlientVysledekTestHba1c, KlientVysledekTestVitaminD, Notifikace, Produkt } from "../types";
import { calculateAge, dateToString, getStavKey, stringToDate } from "../Utils";
import { toast } from "react-toastify";
import './ClientModal.scss';

type Props = {
    title: string,
    isOpen: boolean,
    onClose?: () => void,
    onSave: (data: KlientForm, initialValues?: KlientForm) => void,
    initialValues?: KlientForm,
    products?: Produkt[],
    notifications?: Notifikace[],
}

type TextModalProps = {
    isOpen: boolean,
    title: string,
    text?: string | null,
}

/** Modální dialog s veškerými údaji klienta. */
export function ClientModal({ title, isOpen, onClose, onSave, initialValues, products, notifications }: Props) {
    const [modalProps, setModalProps] = useState<TextModalProps>({
        isOpen: false,
        title: '',
        text: '',
    });

    const {
        control,
        register,
        handleSubmit,
        setValue,
    } = useForm<KlientForm>({ defaultValues: initialValues })

    const notifikaceFieldArray = useFieldArray({
        name: "notifikace",
        control,
    });

    const notifikaceValue = useWatch({
        name: "notifikace",
        control,
    });

    const testyBalanceFieldArray = useFieldArray({
        name: "vysledkyTestBalance",
        control,
    });

    const testyBalanceValue = useWatch({
        name: "vysledkyTestBalance",
        control,
    });

    const testyVitaminDFieldArray = useFieldArray({
        name: "vysledkyTestVitaminD",
        control,
    });

    const testyVitaminDValue = useWatch({
        name: "vysledkyTestVitaminD",
        control,
    });

    const testyHba1cFieldArray = useFieldArray({
        name: "vysledkyTestHba1c",
        control,
    });

    const testyHba1cValue = useWatch({
        name: "vysledkyTestHba1c",
        control,
    });

    const datumNarozeniValue = useWatch({
        name: "datum_narozeni",
        control,
    });

    const notifikaceNarozeninyValue = useWatch({
        name: "notifikace_narozeniny",
        control,
    });

    const clientAge = useMemo(() => {
        if (datumNarozeniValue) {
            return calculateAge(stringToDate(datumNarozeniValue));
        }
    }, [datumNarozeniValue])

    // Pro zobrazení v textovém modálu
    const anamnezaValue = useWatch({
        name: "anamneza",
        control,
    });

    const davkovaniPoznamkyValue = useWatch({
        name: "davkovani_poznamky",
        control,
    });

    const vyhodnoceniValue = useWatch({
        name: "vyhodnoceni",
        control,
    });

    // Mapa kód -> notifikace
    const notificationsMap = useMemo(() => {
        const result: { [key: string]: Notifikace } = {}
        notifications?.forEach(notification => result[notification.kod] = notification);
        return result;
    }, [notifications]);

    const handleAddNotification = () => {
        // TODO toto by chtělo vyřešit nějak normálně, je to hack kvůli tomu že databázově je to PK
        const result: KlientNotifikace = {
            id_klient: initialValues?.id || -1,
            datum_startu: dateToString(new Date()),
        };
        notifikaceFieldArray.append(result);
    }

    const handleAddTestBalance = () => {
        const result: KlientVysledekTestBalance = {
            id_klient: initialValues?.id || -1,
            datum: dateToString(new Date()),
        };
        testyBalanceFieldArray.append(result);
    }

    const handleAddTestVitaminD = () => {
        const result: KlientVysledekTestVitaminD = {
            id_klient: initialValues?.id || -1,
            datum: dateToString(new Date()),
        };
        testyVitaminDFieldArray.append(result);
    }

    const handleAddTestHba1c = () => {
        const result: KlientVysledekTestHba1c = {
            id_klient: initialValues?.id || -1,
            datum: dateToString(new Date()),
        };
        testyHba1cFieldArray.append(result);
    }

    const handleShowModal = (title: string, text?: string | null) => {
        setModalProps({
            isOpen: true,
            title: title,
            text: text,
        })
    }

    const handleCloseModal = () => {
        setModalProps({
            isOpen: false,
            title: '',
            text: '',
        })
    }

    const renderStav = (name: keyof KlientNotifikace, index: number) => {
        return <select className={getStavClassName(notifikaceValue && notifikaceValue[index][name])} {...register(`notifikace.${index}.${name}`)}>
            <option></option>
            {Object.entries(Stav).map(entry => <option key={entry[0]} value={entry[0]}>{entry[1].substring(0, 1)}</option>)}
        </select>
    }

    const renderTitle = (attrName?: string | null) => {
        return <td title={`${attrName ? notificationsMap[attrName].popis : ''}`} style={{ cursor: "pointer" }} onClick={() => {
            if (attrName) {
                navigator.clipboard.writeText(notificationsMap[attrName].popis || '');
                toast.success("Text zkopírován do schránky", { theme: "colored", autoClose: 2000 });
            }
        }}>
            {attrName}
        </td>
    }

    const getStavClassName = (stav?: string | number | null) => {
        if (stav === getStavKey(Stav.NEHOTOVO)) {
            return 'nehotovo';
        } else if (stav === getStavKey(Stav.ROZPRACOVANO)) {
            return 'rozpracovano';
        } else if (stav === getStavKey(Stav.HOTOVO)) {
            return 'hotovo';
        }
        return '';
    }

    // TODO hloupé řešení, nevím jak to udělat dynamicky
    const setValueByName = (klientNotifikace: KlientNotifikace, name: keyof KlientNotifikace, value: string) => {
        switch (name) {
            case "druhy_test_typ":
                klientNotifikace.druhy_test_typ = value;
                break;
            case "email_rekapitulace_typ":
                klientNotifikace.email_rekapitulace_typ = value;
                break;
            case "kontrola_rekapitulace_typ":
                klientNotifikace.kontrola_rekapitulace_typ = value;
                break;
            case "pozvanka_na_prezentaci_typ":
                klientNotifikace.pozvanka_na_prezentaci_typ = value;
                break;
            case "restart_predplatneho_typ":
                klientNotifikace.restart_predplatneho_typ = value;
                break;
            case "ujisteni_klienta_240_typ":
                klientNotifikace.ujisteni_klienta_240_typ = value;
                break;
            case "ujisteni_klienta_90_typ":
                klientNotifikace.ujisteni_klienta_90_typ = value;
                break;
            case "vyhodnoceni_druheho_testu_typ":
                klientNotifikace.vyhodnoceni_druheho_testu_typ = value;
                break;
            case "vyhodnoceni_testu_typ":
                klientNotifikace.vyhodnoceni_testu_typ = value;
                break;
            case "zasilka_test_davkovani_typ":
                klientNotifikace.zasilka_test_davkovani_typ = value;
                break;
        }
    }

    const clearKlientNotifikace = (klientNotifikace: KlientNotifikace) => {
        klientNotifikace.druhy_test_typ = null;
        klientNotifikace.email_rekapitulace_typ = null;
        klientNotifikace.kontrola_rekapitulace_typ = null;
        klientNotifikace.pozvanka_na_prezentaci_typ = null;
        klientNotifikace.restart_predplatneho_typ = null;
        klientNotifikace.ujisteni_klienta_240_typ = null;
        klientNotifikace.ujisteni_klienta_90_typ = null;
        klientNotifikace.vyhodnoceni_druheho_testu_typ = null;
        klientNotifikace.vyhodnoceni_testu_typ = null;
        klientNotifikace.zasilka_test_davkovani_typ = null;
    }

    const updateTypyNotifikaci = (index: number, zpusobOdberu?: string | null, produkt?: string | null) => {
        if (zpusobOdberu && produkt) {
            const product = products?.find(p => p.nazev === produkt);
            const currentValue: KlientNotifikace | undefined = notifikaceValue && { ...notifikaceValue[index] };
            if (product && currentValue) {
                currentValue.zpusob_odberu = zpusobOdberu;
                currentValue.produkt = produkt;
                const key = `notifikace_${zpusobOdberu.toLowerCase()}`;
                const notifikaceStr = product[key as keyof Produkt];
                if (notifikaceStr != null) {
                    if (typeof notifikaceStr === 'string') {
                        const keyMap = [
                            'email_rekapitulace_typ' as keyof KlientNotifikace,
                            'kontrola_rekapitulace_typ' as keyof KlientNotifikace,
                            'zasilka_test_davkovani_typ' as keyof KlientNotifikace,
                            'vyhodnoceni_testu_typ' as keyof KlientNotifikace,
                            'pozvanka_na_prezentaci_typ' as keyof KlientNotifikace,
                            'ujisteni_klienta_90_typ' as keyof KlientNotifikace,
                            'druhy_test_typ' as keyof KlientNotifikace,
                            'vyhodnoceni_druheho_testu_typ' as keyof KlientNotifikace,
                            'restart_predplatneho_typ' as keyof KlientNotifikace,
                            'ujisteni_klienta_240_typ' as keyof KlientNotifikace,
                        ]
                        const notifikaceList = notifikaceStr.split(",");
                        notifikaceList.forEach(kod => {
                            const notifikaceKey = keyMap[parseInt(kod) - 1] as keyof KlientNotifikace;
                            setValueByName(currentValue, notifikaceKey, kod);
                            notifikaceFieldArray.update(index, currentValue);
                        })
                    }
                } else {
                    // Produkt nemá pro tento způsob odběru notifikace nastavené, vymažeme
                    clearKlientNotifikace(currentValue);
                    notifikaceFieldArray.update(index, currentValue);
                }
            }
        } else {
            // Není nastaven produkt - všechny typy notifikací vymažeme
            const current = notifikaceValue && notifikaceValue[index];
            if (current) {
                setValue(`notifikace.${index}.email_rekapitulace_typ`, null);
                setValue(`notifikace.${index}.email_rekapitulace_stav`, null);
                setValue(`notifikace.${index}.kontrola_rekapitulace_typ`, null);
                setValue(`notifikace.${index}.kontrola_rekapitulace_stav`, null);
                setValue(`notifikace.${index}.zasilka_test_davkovani_typ`, null);
                setValue(`notifikace.${index}.zasilka_test_davkovani_stav`, null);
                setValue(`notifikace.${index}.vyhodnoceni_testu_typ`, null);
                setValue(`notifikace.${index}.vyhodnoceni_testu_stav`, null);
                setValue(`notifikace.${index}.pozvanka_na_prezentaci_typ`, null);
                setValue(`notifikace.${index}.pozvanka_na_prezentaci_stav`, null);
                setValue(`notifikace.${index}.ujisteni_klienta_90_typ`, null);
                setValue(`notifikace.${index}.ujisteni_klienta_90_stav`, null);
                setValue(`notifikace.${index}.druhy_test_typ`, null);
                setValue(`notifikace.${index}.druhy_test_stav`, null);
                setValue(`notifikace.${index}.vyhodnoceni_druheho_testu_typ`, null);
                setValue(`notifikace.${index}.vyhodnoceni_druheho_testu_stav`, null);
                setValue(`notifikace.${index}.restart_predplatneho_typ`, null);
                setValue(`notifikace.${index}.restart_predplatneho_stav`, null);
                setValue(`notifikace.${index}.ujisteni_klienta_240_typ`, null);
                setValue(`notifikace.${index}.ujisteni_klienta_240_stav`, null);
            }
        }
    }

    const handleChangeZpusobOdberu = (index: number, e: React.ChangeEvent<HTMLSelectElement>) => {
        updateTypyNotifikaci(index, e.target.value, notifikaceValue && notifikaceValue[index].produkt);
    }

    const handleChangeProdukt = (index: number, e: React.ChangeEvent<HTMLSelectElement>) => {
        updateTypyNotifikaci(index, notifikaceValue && notifikaceValue[index].zpusob_odberu, e.target.value);
    }

    // -------- Obecná pole

    const getDatumNarozeniClassName = (value?: boolean | null) => {
        return value ? "oranzova" : "";
    }

    // -------- Testy balance

    const getPomerO6O3ClassName = (value?: string | null) => {
        if (!value || !value.endsWith(":1")) {
            return '';
        }
        const hodnota = parseFloat(value.substring(0, value.length - 2).replaceAll(",", "."));
        if (clientAge == null || clientAge >= 12) {
            if (hodnota > 9) {
                return 'cervena';
            } else if (hodnota <= 9 && hodnota > 3) {
                return 'oranzova';
            } else if (hodnota <= 3 && hodnota >= 0) {
                return 'zelena';
            }
        } else {
            if (hodnota > 9) {
                return 'cervena';
            } else if (hodnota <= 9 && hodnota > 5) {
                return 'oranzova';
            } else if (hodnota <= 5 && hodnota >= 0) {
                return 'zelena';
            }
        }
    }

    const getOchranaProtiZanetuClassName = (value?: string | null) => {
        if (value == null || value === "") {
            return '';
        }
        const procenta = parseFloat(value.replaceAll(",", "."));
        if (clientAge == null || clientAge >= 12) {
            if (procenta >= 0 && procenta < 50) {
                return 'cervena';
            } else if (procenta >= 50 && procenta < 90) {
                return 'oranzova';
            } else if (procenta >= 90 && procenta <= 100) {
                return 'zelena';
            }
        } else {
            if (procenta >= 0 && procenta < 45) {
                return 'cervena';
            } else if (procenta >= 45 && procenta < 64) {
                return 'oranzova';
            } else if (procenta >= 64 && procenta <= 100) {
                return 'zelena';
            }
        }
    }

    const getOmega3ClassName = (value?: string | null) => {
        if (value == null || value === "") {
            return '';
        }
        const procenta = parseFloat(value.replaceAll(",", "."));
        if (clientAge == null || clientAge >= 12) {
            if (procenta >= 0 && procenta < 4) {
                return 'cervena';
            } else if (procenta >= 4 && procenta < 8) {
                return 'oranzova';
            } else if (procenta >= 8) {
                return 'zelena';
            }
        } else {
            if (procenta >= 0 && procenta < 4) {
                return 'cervena';
            } else if (procenta >= 4 && procenta < 6) {
                return 'oranzova';
            } else if (procenta >= 6) {
                return 'zelena';
            }
        }
    }

    const getDusevniOdolnostClassName = (value?: string | null) => {
        if (!value || !value.endsWith(":1")) {
            return '';
        }
        const hodnota = parseFloat(value.substring(0, value.length - 2).replaceAll(",", "."));
        if (clientAge == null || clientAge >= 12) {
            if (hodnota > 3) {
                return 'cervena';
            } else if (hodnota <= 3 && hodnota > 1) {
                return 'oranzova';
            } else if (hodnota <= 1 && hodnota >= 0) {
                return 'zelena';
            }
        } else {
            if (hodnota > 3) {
                return 'cervena';
            } else if (hodnota <= 3 && hodnota > 1.5) {
                return 'oranzova';
            } else if (hodnota <= 1.5 && hodnota >= 0) {
                return 'zelena';
            }
        }
    }

    const getPropustnostMembranyClassName = (value?: string | null) => {
        if (!value || !value.endsWith(":1")) {
            return '';
        }
        const hodnota = parseFloat(value.substring(0, value.length - 2).replaceAll(",", "."));
        if (clientAge == null || clientAge >= 12) {
            if (hodnota > 9) {
                return 'cervena';
            } else if (hodnota <= 9 && hodnota > 4) {
                return 'oranzova';
            } else if (hodnota <= 4 && hodnota >= 0) {
                return 'zelena';
            }
        } else {
            if (hodnota > 9) {
                return 'cervena';
            } else if (hodnota <= 9 && hodnota > 6) {
                return 'oranzova';
            } else if (hodnota <= 6 && hodnota >= 0) {
                return 'zelena';
            }
        }
    }

    const getKyselinaArachidovaClassName = (value?: string | null) => {
        if (value == null || value === "") {
            return '';
        }
        const procenta = parseFloat(value.replaceAll(",", "."));
        if (clientAge == null || clientAge >= 12) {
            if ((procenta >= 0 && procenta < 4.5) || procenta >= 12.6) {
                return 'cervena';
            } else if ((procenta >= 4.5 && procenta < 6.5) || (procenta >= 9.6 && procenta <= 12.5)) {
                return 'oranzova';
            } else if (procenta >= 6.5 && procenta <= 9.5) {
                return 'zelena';
            }
        } else {
            if ((procenta >= 0 && procenta < 4.5) || procenta >= 12.6) {
                return 'cervena';
            } else if ((procenta >= 4.5 && procenta < 6.9) || (procenta >= 10.2 && procenta <= 12.5)) {
                return 'oranzova';
            } else if (procenta >= 6.9 && procenta <= 10.1) {
                return 'zelena';
            }
        }
    }

    // -------- Testy Vitamin D

    const getNmolNaLClassName = (value?: string | null) => {
        if (!value || value === '') {
            return '';
        }
        const hodnota = parseInt(value);
        if ((hodnota >= 0 && hodnota <= 19) || hodnota >= 199) {
            return 'cervena';
        } else if ((hodnota >= 20 && hodnota <= 49) || (hodnota >= 126 && hodnota <= 200)) {
            return 'oranzova';
        } else if (hodnota >= 50 && hodnota <= 125) {
            return 'zelena';
        }
    }

    // -------- Testy HbAc1

    const getMmolNaMolClassName = (value?: string | null) => {
        if (!value || value === '') {
            return '';
        }
        const hodnota = parseInt(value);
        if (hodnota >= 48) {
            return 'cervena';
        } else if (hodnota >= 40 && hodnota <= 47) {
            return 'oranzova';
        } else if (hodnota >= 35 && hodnota <= 39) {
            return 'svetle-zelena';
        } else if (hodnota >= 0 && hodnota <= 34) {
            return 'zelena';
        }
    }

    return <Modal fullscreen show={isOpen} onHide={onClose} size="xl" backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(data => onSave(data, initialValues))}>
                <Row>
                    <Col md={3}>
                        <Form.Label>Jméno</Form.Label>
                        <Form.Control {...register("jmeno")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Příjmení</Form.Label>
                        <Form.Control {...register("prijmeni")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Ulice</Form.Label>
                        <Form.Control {...register("ulice")} />
                    </Col>
                    <Col md={3}>
                        <Row>
                            <Col md={7}>
                                <Form.Label>Město</Form.Label>
                                <Form.Control {...register("mesto")} />
                            </Col>
                            <Col md={5}>
                                <Form.Label>PSČ</Form.Label>
                                <Form.Control {...register("psc")} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Label>Telefon</Form.Label>
                        <Form.Control {...register("telefon")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control {...register("email")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Datum narození</Form.Label>
                        <Row>
                            <Col>
                                <Form.Control className={getDatumNarozeniClassName(initialValues?.notifikace_narozeniny)} type="date" {...register("datum_narozeni")} />
                            </Col>
                            {notifikaceNarozeninyValue ?
                                <Col xs="auto">
                                    <Button variant="secondary" title="Kliknutím zrušíte notifikaci na narozeniny" onClick={() => {
                                        setValue("notifikace_narozeniny", false);
                                    }}>X</Button>
                                </Col> : ""
                            }
                        </Row>
                    </Col>
                    <Col md={3}>
                        <Form.Label>Stravování</Form.Label>
                        <Form.Control {...register("stravovani")} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Label>Hmotnost</Form.Label>
                        <Form.Control {...register("vaha")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Výška</Form.Label>
                        <Form.Control {...register("vyska")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Užívání Omega 3</Form.Label>
                        <Form.Control {...register("omega3")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Zákaznické číslo</Form.Label>
                        <Form.Control {...register("zakaznicke_cislo")} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Label>Datum registrace</Form.Label>
                        <Form.Control {...register("datum_registrace")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Zapisovatel</Form.Label>
                        <Form.Control {...register("zapisovatel")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Z4F</Form.Label>
                        <Form.Control {...register("z4f")} />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Způsob platby</Form.Label>
                        <Form.Control {...register("zpusob_platby")} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={4}>
                        <Form.Label>Anamnéza a očekávání klienta</Form.Label>
                        <Form.Control as="textarea" {...register("anamneza")} onDoubleClick={() => alert(anamnezaValue)} title="Dvojklikem otevřete v novém okně" />
                    </Col>
                    <Col md={4}>
                        <Form.Label>Dávkování a poznámky</Form.Label>
                        <Form.Control as="textarea" {...register("davkovani_poznamky")} onDoubleClick={() => alert(davkovaniPoznamkyValue)} title="Dvojklikem otevřete v novém okně" />
                    </Col>
                    <Col md={4}>
                        <Form.Label>Změny zdravotního stavu - výsledky</Form.Label>
                        <Form.Control as="textarea" {...register("vyhodnoceni")} onDoubleClick={() => alert(vyhodnoceniValue)} title="Dvojklikem otevřete v novém okně" />
                    </Col>
                </Row>
                <h4 className="mt-5">Produktové informace s notifikacemi</h4>
                <p style={{ fontSize: 12 }}><span className="hotovo">H</span> - hotovo, <span className="rozpracovano">R</span> - rozpracováno, <span className="nehotovo">N</span> - nehotovo</p>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th rowSpan={2}>Způsob odběru</th>
                            <th rowSpan={2}>Datum startu</th>
                            <th rowSpan={2}>Zákaznické číslo</th>
                            <th rowSpan={2}>Množství</th>
                            <th rowSpan={2}>Produkt</th>
                            <th rowSpan={2}>Přerušení</th>
                            <th colSpan={2}>Email s rekapitulací</th>
                            <th colSpan={2}>Kontrola rekapitulace</th>
                            <th colSpan={2}>Zásilka, test, dávkování</th>
                            <th colSpan={2}>Vyhodnocení testu</th>
                            <th colSpan={2}>Pozvánka na prezentaci</th>
                            <th colSpan={2}>Ujištění klienta o správnosti 90 dnů</th>
                            <th colSpan={2}>Další test</th>
                            <th colSpan={2}>Vyhodnocení dalšího testu</th>
                            <th colSpan={2}>Restart předplatného</th>
                            <th colSpan={2}>Ujištění klienta o správnosti 240 dnů</th>
                        </tr>
                        <tr>
                            {Array.from({ length: 10 }, (_, k) => (
                                <React.Fragment key={k}>
                                    <th>Typ</th>
                                    <th>Stav</th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {notifikaceFieldArray.fields.map((field, index) => {
                            const registerObjZpusobOdberu = { ...register(`notifikace.${index}.zpusob_odberu`) };
                            const registerObjProdukt = { ...register(`notifikace.${index}.produkt`) };
                            return <tr key={field.id}>
                                <td style={{ textWrap: "nowrap" }}>
                                    <select {...registerObjZpusobOdberu} onChange={(e) => {
                                        registerObjZpusobOdberu.onChange(e);
                                        handleChangeZpusobOdberu(index, e);
                                    }}>
                                        <option></option>
                                        {Object.entries(TypNotifikace).map(entry => <option key={entry[0]} value={entry[0]}>{entry[1]}</option>)}
                                    </select>
                                    <FontAwesomeIcon icon={faTrash} title="Odstranit" className="action-icon" style={{ marginLeft: '10px' }} onClick={() => notifikaceFieldArray.remove(index)} />
                                </td>
                                <td className="px-0">
                                    <input type="date" style={{ width: 110 }} key={field.id} {...register(`notifikace.${index}.datum_startu`)} />
                                </td>
                                <td className="px-0">
                                    <input style={{ width: 130 }} key={field.id} {...register(`notifikace.${index}.zakaznicke_cislo`)} />
                                </td>
                                <td className="px-0">
                                    <input style={{ width: 80 }} key={field.id} {...register(`notifikace.${index}.mnozstvi`)} />
                                </td>
                                <td className="px-0">
                                    <select {...registerObjProdukt} onChange={(e) => {
                                        registerObjProdukt.onChange(e);
                                        handleChangeProdukt(index, e);
                                    }}>
                                        <option></option>
                                        {products?.map(product => <option key={product.nazev} value={product.nazev}>{product.nazev}</option>)}
                                    </select>
                                </td>
                                <td className="px-0">
                                    <select {...register(`notifikace.${index}.preruseni`)}>
                                        <option></option>
                                        {Object.values(TypPreruseni).map(preruseni => <option key={preruseni} value={preruseni}>{preruseni}</option>)}
                                    </select>
                                </td>
                                {renderTitle(field.email_rekapitulace_typ)}
                                <td className="stav">
                                    {field.email_rekapitulace_typ && renderStav('email_rekapitulace_stav', index)}
                                </td>
                                {renderTitle(field.kontrola_rekapitulace_typ)}
                                <td className="stav">
                                    {field.kontrola_rekapitulace_typ && renderStav('kontrola_rekapitulace_stav', index)}
                                </td>
                                {renderTitle(field.zasilka_test_davkovani_typ)}
                                <td className="stav">
                                    {field.zasilka_test_davkovani_typ && renderStav('zasilka_test_davkovani_stav', index)}
                                </td>
                                {renderTitle(field.vyhodnoceni_testu_typ)}
                                <td className="stav">
                                    {field.vyhodnoceni_testu_typ && renderStav('vyhodnoceni_testu_stav', index)}
                                </td>
                                {renderTitle(field.pozvanka_na_prezentaci_typ)}
                                <td className="stav">
                                    {field.pozvanka_na_prezentaci_typ && renderStav('pozvanka_na_prezentaci_stav', index)}
                                </td>
                                {renderTitle(field.ujisteni_klienta_90_typ)}
                                <td className="stav">
                                    {field.ujisteni_klienta_90_typ && renderStav('ujisteni_klienta_90_stav', index)}
                                </td>
                                {renderTitle(field.druhy_test_typ)}
                                <td className="stav">
                                    {field.druhy_test_typ && renderStav('druhy_test_stav', index)}
                                </td>
                                {renderTitle(field.vyhodnoceni_druheho_testu_typ)}
                                <td className="stav">
                                    {field.vyhodnoceni_druheho_testu_typ && renderStav('vyhodnoceni_druheho_testu_stav', index)}
                                </td>
                                {renderTitle(field.restart_predplatneho_typ)}
                                <td className="stav">
                                    {field.restart_predplatneho_typ && renderStav('restart_predplatneho_stav', index)}
                                </td>
                                {renderTitle(field.ujisteni_klienta_240_typ)}
                                <td className="stav">
                                    {field.ujisteni_klienta_240_typ && renderStav('ujisteni_klienta_240_stav', index)}
                                </td>
                            </tr>
                        })}
                        <tr>
                            <td colSpan={26}>
                                <Button title="Přidat záznam" onClick={handleAddNotification}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <h4 className="mt-5">Testy</h4>
                <Table className="mt-3" striped bordered hover style={{ width: "auto" }}>
                    <thead>
                        <tr>
                            <th style={{ width: 0 }}>Balance</th>
                            <th style={{ width: 0 }}>Datum</th>
                            <th style={{ width: 0 }}>Poměr O6 : O3</th>
                            <th style={{ width: 0 }}>Ochrana proti zánětu %</th>
                            <th style={{ width: 0 }}>Omega 3 min. 8%</th>
                            <th style={{ width: 0 }}>Duševní odolnost</th>
                            <th style={{ width: 0 }}>Propustnost membrány</th>
                            <th style={{ width: 0 }}>Kyselina Arachid. %</th>
                            <th>ID testu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {testyBalanceFieldArray.fields.map((field, index) =>
                            <tr key={index}>
                                <td style={{ width: 100 }}>
                                    <FontAwesomeIcon icon={faTrash} title="Odstranit" className="action-icon" style={{ marginRight: '10px' }} onClick={() => testyBalanceFieldArray.remove(index)} />
                                    {index + 1}. test
                                </td>
                                <td>
                                    <input style={{ width: 110 }} type="date" key={field.id} {...register(`vysledkyTestBalance.${index}.datum`)} />
                                </td>
                                <td>
                                    <input style={{ width: 60 }} className={getPomerO6O3ClassName(testyBalanceValue && testyBalanceValue[index]?.pomer_O6_O3)} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.pomer_O6_O3`)} />
                                </td>
                                <td>
                                    <input style={{ width: 60 }} className={getOchranaProtiZanetuClassName(testyBalanceValue && testyBalanceValue[index]?.ochrana_proti_zanetu)} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.ochrana_proti_zanetu`)} />
                                </td>
                                <td>
                                    <input style={{ width: 60 }} className={getOmega3ClassName(testyBalanceValue && testyBalanceValue[index]?.omega3)} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.omega3`)} />
                                </td>
                                <td>
                                    <input style={{ width: 60 }} className={getDusevniOdolnostClassName(testyBalanceValue && testyBalanceValue[index]?.dusevni_odolnost)} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.dusevni_odolnost`)} />
                                </td>
                                <td>
                                    <input style={{ width: 60 }} className={getPropustnostMembranyClassName(testyBalanceValue && testyBalanceValue[index]?.propustnost_membrany)} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.propustnost_membrany`)} />
                                </td>
                                <td>
                                    <input style={{ width: 60 }} className={getKyselinaArachidovaClassName(testyBalanceValue && testyBalanceValue[index]?.kyselina_arachidova)} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.kyselina_arachidova`)} />
                                </td>
                                <td>
                                    <input style={{ width: 170 }} type="text" key={field.id} {...register(`vysledkyTestBalance.${index}.id_testu`)} />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={9}>
                                <Button title="Přidat test" onClick={handleAddTestBalance}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table className="mt-3" striped bordered hover style={{ width: "auto" }}>
                    <thead>
                        <tr>
                            <th style={{ width: 0 }}>Vitamin&nbsp;D</th>
                            <th style={{ width: 0 }}>Datum</th>
                            <th style={{ width: 0 }}>nmol/L</th>
                            <th>ID testu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {testyVitaminDFieldArray.fields.map((field, index) =>
                            <tr key={index}>
                                <td style={{ width: 100 }}>
                                    <FontAwesomeIcon icon={faTrash} title="Odstranit" className="action-icon" style={{ marginRight: '10px' }} onClick={() => testyVitaminDFieldArray.remove(index)} />
                                    {index + 1}. test
                                </td>
                                <td>
                                    <input style={{ width: 110 }} type="date" key={field.id} {...register(`vysledkyTestVitaminD.${index}.datum`)} />
                                </td>
                                <td>
                                    <input style={{ width: 50 }} className={getNmolNaLClassName(testyVitaminDValue && testyVitaminDValue[index]?.nmol_l)} type="text" key={field.id} {...register(`vysledkyTestVitaminD.${index}.nmol_l`)} />
                                </td>
                                <td>
                                    <input style={{ width: 170 }} type="text" key={field.id} {...register(`vysledkyTestVitaminD.${index}.id_testu`)} />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={4}>
                                <Button title="Přidat test" onClick={handleAddTestVitaminD}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <Table className="mt-3" striped bordered hover style={{ width: "auto" }}>
                    <thead>
                        <tr>
                            <th style={{ width: 0 }}>HbA1c</th>
                            <th style={{ width: 0 }}>Datum</th>
                            <th style={{ width: 0 }}>mmol/mol</th>
                            <th>ID testu</th>
                        </tr>
                    </thead>
                    <tbody>
                        {testyHba1cFieldArray.fields.map((field, index) =>
                            <tr key={index}>
                                <td style={{ width: 100 }}>
                                    <FontAwesomeIcon icon={faTrash} title="Odstranit" className="action-icon" style={{ marginRight: '10px' }} onClick={() => testyHba1cFieldArray.remove(index)} />
                                    {index + 1}. test
                                </td>
                                <td>
                                    <input style={{ width: 110 }} type="date" key={field.id} {...register(`vysledkyTestHba1c.${index}.datum`)} />
                                </td>
                                <td>
                                    <input style={{ width: 80 }} className={getMmolNaMolClassName(testyHba1cValue && testyHba1cValue[index]?.mmol_mol)} type="text" key={field.id} {...register(`vysledkyTestHba1c.${index}.mmol_mol`)} />
                                </td>
                                <td>
                                    <input style={{ width: 170 }} type="text" key={field.id} {...register(`vysledkyTestHba1c.${index}.id_testu`)} />
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan={4}>
                                <Button title="Přidat test" onClick={handleAddTestHba1c}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Storno
            </Button>
            <Button variant="primary" onClick={handleSubmit(data => onSave(data, initialValues))}>
                Uložit
            </Button>
        </Modal.Footer>
    </Modal>
}