import { Navbar, Nav, NavDropdown, NavItem, NavLink } from "react-bootstrap";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router";

export default function Header() {
    const auth = useAuth();
    const navigate = useNavigate();

    return <Navbar variant='dark' expand="lg">
        <Navbar.Brand href='/'>ClientHub</Navbar.Brand>
        <Nav className="nav">
            {/* TODO opravit */}
            {auth?.role === 'ADMIN' &&
                <>
                    <NavItem>
                        <NavLink
                            active
                            href="/"
                        >
                            Výpis notifikací
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active
                            href="/klienti"
                        >
                            Klienti
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active
                            href="/produkty"
                        >
                            Produkty
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            active
                            href="/notifikace"
                        >
                            Notifikace
                        </NavLink>
                    </NavItem>
                </>
            }
        </Nav>
        {auth?.login &&
            <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="nav">
                        <NavDropdown align="end" title={auth?.login} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => navigate('/password-change')}>Změnit heslo</NavDropdown.Item>
                            <NavDropdown.Item onClick={auth?.logout}>Odhlásit se</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </>
        }
    </Navbar>
}