import { Button, Table } from 'react-bootstrap';
import AppPage from './AppPage';
import { faPencil, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { addEditClient, deleteClient, getClient, getClients } from '../api/ClientsApi';
import { Klient } from '../types';
import { ClientModal } from '../modals/ClientModal';
import { useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { apiCall } from '../api/Api';
import { toast } from 'react-toastify';
import { KlientForm, SortConfig } from '../types/Types';
import { QUERY_CLIENT_NOTIFICATIONS, QUERY_CLIENTS, QUERY_NOTIFICATIONS, QUERY_PRODUCTS } from '../api/queries';
import { getProducts } from '../api/ProductsApi';
import { getNotifications } from '../api/NotificationsApi';
import { dateToHumanString, filterSort, getSortableHeaderClassName, stringToDate } from '../Utils';
import './KlientiPage.scss';

export default function KlientiPage() {

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('Nový klient');
  const [initialValues, setInitialValues] = useState<KlientForm>();
  const [searchValue, setSearchValue] = useState<string>();
  const [sortConfig, setSortConfig] = useState<SortConfig<Klient>>();

  // Seznam klientů
  const { isLoading, data } = useQuery({
    queryKey: [QUERY_CLIENTS],
    queryFn: getClients,
  })

  // Číselník produktů
  const productsQuery = useQuery({
    queryKey: [QUERY_PRODUCTS],
    queryFn: getProducts
  });

  // Číselník notifikací
  const notificationsQuery = useQuery({
    queryKey: [QUERY_NOTIFICATIONS],
    queryFn: getNotifications,
  })

  // Filtrace klientů dle search query / řazení
  const clients = useMemo(() => filterSort(data, searchValue, ['jmeno', 'prijmeni'], sortConfig), [data, searchValue, sortConfig]);

  const queryClient = useQueryClient();

  const handleClose = () => {
    if (window.confirm("Veškeré neuložené změny budou ztraceny!")) {
      setModalIsOpen(false);
    }
  }

  const handleSave = async (data: Klient, initialValues?: Klient) => {
    try {
      await apiCall(() => addEditClient(data));
      toast.success("Klient uložen", { theme: "colored" });
      setModalIsOpen(false);
      queryClient.invalidateQueries({ queryKey: [QUERY_CLIENTS, QUERY_CLIENT_NOTIFICATIONS] });
    } catch (e: any) {
      // Není potřeba nic dělat
    }
  }

  const handleCreate = () => {
    setModalTitle("Nový klient");
    setInitialValues(undefined);
    setModalIsOpen(true);
  }

  const handleOpenModal = (data?: KlientForm) => {
    setModalTitle("Úprava klienta");
    setInitialValues(data);
    setModalIsOpen(true);
  }

  const handleEdit = async (id?: number) => {
    if (!id) {
      return;
    }
    const client = await getClient(id);
    if (client) {
      handleOpenModal(client);
    }
  }

  const handleDelete = async (klient: Klient) => {
    confirmAlert({
      title: 'Smazání klienta',
      message: `Opravdu si přejete odstranit klienta ${klient.jmeno} ${klient.prijmeni}?`,
      buttons: [
        {
          label: 'Smazat',
          onClick: async () => {
            try {
              await apiCall(() => deleteClient(klient.id));
              toast.success("Klient odstraněn", { theme: "colored" });
              setModalIsOpen(false);
              queryClient.invalidateQueries({ queryKey: [QUERY_CLIENTS] })
            } catch (e: any) {
              // Není potřeba nic dělat
            }
          }
        },
        {
          label: 'Storno',
        }
      ]
    });
  }

  // TODO duplicita, vytrhnout
  const handleSort = (key: keyof Klient) => {
    if (sortConfig == null) {
      setSortConfig({ key: key, direction: 'ascending' });
      return;
    }
    let newSortConfig = { ...sortConfig };
    if (key === sortConfig?.key) {
      newSortConfig.direction = newSortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
    newSortConfig.key = key;
    setSortConfig(newSortConfig);
  }

  const renderTable = () => {
    return <Table className='mt-3' striped bordered hover style={{ justifySelf: 'center' }}>
      <thead>
        <tr>
          <th className={getSortableHeaderClassName(sortConfig, 'jmeno')} onClick={() => handleSort('jmeno')}>Jméno</th>
          <th className={getSortableHeaderClassName(sortConfig, 'prijmeni')} onClick={() => handleSort('prijmeni')}>Příjmení</th>
          <th className={getSortableHeaderClassName(sortConfig, 'datum_narozeni')} onClick={() => handleSort('datum_narozeni')}>Datum narození</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {clients?.map(klient =>
          <tr key={klient.id}>
            <td>{klient.jmeno}</td>
            <td>{klient.prijmeni}</td>
            <td>{dateToHumanString(stringToDate(klient.datum_narozeni))}</td>
            <td>
              <FontAwesomeIcon icon={faPencil} title='Upravit' className='action-icon' onClick={() => handleEdit(klient.id)} />
              <FontAwesomeIcon icon={faTrash} title='Odstranit' className='action-icon' onClick={() => handleDelete(klient)} />
            </td>
          </tr>)}
      </tbody>
    </Table>
  }

  // TODO hezčí načítání
  if (isLoading) return <p>Načítám data...</p>

  return <AppPage>
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Klienti</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button onClick={handleCreate} style={{ alignSelf: 'flex-end', marginBottom: '20px' }}><FontAwesomeIcon icon={faPlus} /> Nový</Button>
          <input type="text" placeholder="Vyhledat..." onChange={(e) => setSearchValue(e.target.value)} />
          {renderTable()}
        </div>
      </div>
      {modalIsOpen && <ClientModal title={modalTitle} isOpen={modalIsOpen} onClose={handleClose} onSave={handleSave} initialValues={initialValues} products={productsQuery.data} notifications={notificationsQuery.data} />}
    </>
  </AppPage>
}
