import React, { ReactNode, useContext, useState } from "react"
import { useEffect } from "react"
import { useJwt } from "react-jwt";
import { deleteToken, getToken, storeToken } from "../Utils";
import { User } from "../types";

export type AuthContextProps = {
  login?: string,
  role?: string, // TODO opravit
  setToken: (name: string) => void,
  logout: () => void,
}

type ContextProps = {
  children: ReactNode
}

const authContext = React.createContext<AuthContextProps | null>(null);

export function ProvideAuth(props: ContextProps) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext);
}

function useProvideAuth(): AuthContextProps {
  const [user, setUser] = useState<User | undefined>();
  const [token, setToken] = useState<string | null>(getToken());
  const { decodedToken } = useJwt(token || '');

  useEffect(() => {
    if (token && token.length > 0) {
      storeToken(token);
    } else {
      deleteToken();
    }
  }, [token]);

  useEffect(() => {
    if (decodedToken) {
      const login = (decodedToken as any).login;
      const role = (decodedToken as any).role
      setUser({ login, role })
    } else {
      setUser(undefined);
    }
  }, [decodedToken]);

  function logout() {
    setToken(null);
    setUser(undefined);
  }

  return {
    login: user?.login,
    role: user?.role,
    setToken,
    logout,
  }
}
