import { Klient } from "../types";
import { KlientForm } from "../types/Types";
import { api } from "./Api";

const BASE_PATH = '/api/clients';

export const getClients = async () => {
    return await api.get<Klient[]>(`${BASE_PATH}/get`);
}

export const getClient = async (id: number) => {
    return await api.get<KlientForm | null>(`${BASE_PATH}/get?id=${id}`);
}

export const addEditClient = async (klient: Klient) => {
    return await api.post<any, any>(`${BASE_PATH}/addEdit`, JSON.stringify({ ...klient }));
}

export const deleteClient = async (id?: number) => {
    return await api.delete<any>(`${BASE_PATH}/delete?id=${id}`);
}