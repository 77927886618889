import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ProduktForm, TypNotifikace } from "../types/Types";

type Props = {
    title: string,
    isOpen: boolean,
    onClose?: () => void,
    onSave: (data: ProduktForm, initialValues?: ProduktForm) => void,
    initialValues?: ProduktForm,
}

export function ProductModal({ title, isOpen, onClose, onSave, initialValues }: Props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ProduktForm>({ defaultValues: { ...initialValues } })

    return <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(data => onSave(data, initialValues))}>
                <Form.Control placeholder='Název produktu' {...register("nazev", { required: true })} />
                {errors.nazev && <span className='error'>Pole je povinné</span>}
                {Object.entries(TypNotifikace).map(entry => <span key={entry[0]}>
                    <Form.Label>Notifikace {entry[1]}</Form.Label>
                    <Form.Control {...register(`notifikace_${entry[0].toLowerCase()}`)} />
                </span>
                )}
                <Form.Label title="Volitelné pořadí pro řazení v tabulkách. Produkty s nizším pořadím budou řazeny nad produkty s vyšším pořadím.">Pořadí</Form.Label>
                <Form.Control type="number" step="1" title="Volitelné pořadí pro řazení v tabulkách. Produkty s nizším pořadím budou řazeny nad produkty s vyšším pořadím." {...register(`poradi`)} />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Storno
            </Button>
            <Button variant="primary" onClick={handleSubmit(data => onSave(data, initialValues))}>
                Uložit
            </Button>
        </Modal.Footer>
    </Modal>
}