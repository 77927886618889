import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Notifikace } from "../types";

type Props = {
    title: string,
    isOpen: boolean,
    onClose?: () => void,
    onSave: (data: Notifikace, initialValues?: Notifikace) => void,
    initialValues?: Notifikace,
}

export function NotificationModal({ title, isOpen, onClose, onSave, initialValues }: Props) {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Notifikace>({ defaultValues: { kod: initialValues?.kod, nazev: initialValues?.nazev, popis: initialValues?.popis, lhuta: initialValues?.lhuta, poradi: initialValues?.poradi } })

    return <Modal show={isOpen} onHide={onClose} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit(data => onSave(data, initialValues))}>
                <Form.Control placeholder='Kód' {...register("kod", { required: true })} />
                {errors.kod && <span className='error'>Pole je povinné</span>}
                <Form.Control placeholder='Název' {...register("nazev", { required: true })} />
                {errors.nazev && <span className='error'>Pole je povinné</span>}
                <Form.Control as="textarea" rows={5} placeholder='Popis' {...register("popis", { required: false })} />
                <Form.Control placeholder='Lhůta' type="number" {...register("lhuta", { required: true })} />
                {errors.lhuta && <span className='error'>Pole je povinné</span>}
                <Form.Control placeholder='Pořadí' type="number" step={1} {...register("poradi")} title="Volitelné pořadí pro řazení v tabulkách. Notifikace s nizším pořadím budou řazeny nad notifikace s vyšším pořadím." />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                Storno
            </Button>
            <Button variant="primary" onClick={handleSubmit(data => onSave(data, initialValues))}>
                Uložit
            </Button>
        </Modal.Footer>
    </Modal>
}