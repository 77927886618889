import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ProvideAuth } from './context/auth';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import PasswordChangePage from './pages/PasswordChangePage';
import LoginPage from './pages/LoginPage';
import ProduktyPage from './pages/ProduktyPage';
import NotifikacePage from './pages/NotifikacePage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import KlientiPage from './pages/KlientiPage';
import './index.scss';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/password-change",
    element: <PasswordChangePage />,
  },
  {
    path: "/klienti",
    element: <KlientiPage />,
  },
  {
    path: "/produkty",
    element: <ProduktyPage />,
  },
  {
    path: "/notifikace",
    element: <NotifikacePage />,
  },
  {
    path: "/",
    element: <App />,
  },
]);

root.render(
  <React.StrictMode>
    <ProvideAuth>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ProvideAuth>
  </React.StrictMode>
);
