import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../components/Header';
// import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

type Props = {
    children: string | JSX.Element | JSX.Element[]
}

function AppPage({ children }: Props) {
    return (
        <>
            <Header />
            {children}
            {/* <Footer /> */}
            <ToastContainer />
        </>
    );
}

export default AppPage;
