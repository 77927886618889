import { Button } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import AppPage from './AppPage';
import { changePassword } from '../api/AuthApi';
import { apiCall } from '../api/Api';

type Inputs = {
  passwordOld: string,
  passwordNew: string,
  passwordNew2: string,
}

const MIN_LENGTH = 8;
const MAX_LENGTH = 64;
const requiredValidation = { value: true, message: "Pole je povinné" }
const minLengthValidation = { value: MIN_LENGTH, message: "Délka musí být 8-64 znaků" }
const maxLengthValidation = { value: MAX_LENGTH, message: "Délka musí být 8-64 znaků" }

export default function PasswordChangePage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    // TODO vymyslet nějak toast při úspěchu
    const result = await apiCall(() => changePassword(data.passwordOld, data.passwordNew));
    console.log("Výsledek změny hesla", result);
  }

  const validatePasswordMatch = (value: any, formValues: any) => {
    if (formValues.passwordNew !== formValues.passwordNew2) {
      return "Hesla se neshodují";
    }
    return true;
  }

  return <AppPage>
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <h4>Změna hesla</h4>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', maxWidth: '50%', alignItems: 'center' }}>
        <input autoComplete='current-password' placeholder='Původní heslo' {...register("passwordOld", { required: requiredValidation })} style={{ marginTop: '10px' }} />
        {errors.passwordOld && <span className='error'>{errors.passwordOld.message}</span>}
        <input autoComplete='new-password' type='password' placeholder='Nové heslo' {...register("passwordNew", { required: requiredValidation, minLength: minLengthValidation, maxLength: maxLengthValidation })} style={{ marginTop: '10px' }} />
        {errors.passwordNew && <span className='error'>{errors.passwordNew.message}</span>}
        <input autoComplete='new-password' type='password' placeholder='Nové heslo znovu' {...register("passwordNew2", { required: requiredValidation, minLength: minLengthValidation, maxLength: maxLengthValidation, validate: validatePasswordMatch })} style={{ marginTop: '10px' }} />
        {errors.passwordNew2 && <span className='error'>{errors.passwordNew2.message}</span>}
        <Button type='submit' style={{ marginTop: "20px" }}>Změnit</Button>
      </form>
    </div>
  </AppPage>
}
