import { Button, Table } from 'react-bootstrap';
import AppPage from './AppPage';
import { faPlus, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiCall } from '../api/Api';
import { toast } from 'react-toastify';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Notifikace } from '../types';
import { addNotification, deleteNotification, editNotification, getNotifications } from '../api/NotificationsApi';
import { NotificationModal } from '../modals/NotificationModal';
import { QUERY_NOTIFICATIONS } from '../api/queries';
import { SortConfig } from '../types/Types';
import { filterSort, getSortableHeaderClassName } from '../Utils';

export default function NotifikacePage() {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>('Nová notifikace');
  const [initialValues, setInitialValues] = useState<Notifikace>();
  const [sortConfig, setSortConfig] = useState<SortConfig<Notifikace>>();

  const { isLoading, data } = useQuery({
    queryKey: [QUERY_NOTIFICATIONS],
    queryFn: getNotifications,
  })
  const queryClient = useQueryClient();

  const notifikace = useMemo(() => filterSort(data, undefined, undefined, sortConfig), [data, sortConfig]);

  const handleClose = () => {
    if (window.confirm("Veškeré neuložené změny budou ztraceny!")) {
      setModalIsOpen(false);
    }
  }

  const handleSave = async (data: Notifikace, initialValues?: Notifikace) => {
    try {
      if (initialValues) {
        await apiCall(() => editNotification(initialValues.kod, data.nazev, data.popis, data.lhuta, data.poradi));
      } else {
        await apiCall(() => addNotification(data.kod, data.nazev, data.popis, data.lhuta, data.poradi));
      }
      toast.success("Notifikace uložena", { theme: "colored" });
      setModalIsOpen(false);
      queryClient.invalidateQueries({ queryKey: [QUERY_NOTIFICATIONS] })
    } catch (e: any) {
      // Není potřeba nic dělat
    }
  }

  const handleCreate = () => {
    setModalTitle("Nová notifikace");
    setInitialValues(undefined);
    setModalIsOpen(true);
  }

  const handleEdit = (notification: Notifikace) => {
    setModalTitle("Úprava notifikace");
    setInitialValues(notification);
    setModalIsOpen(true);
  }

  const handleDelete = async (notification: Notifikace) => {
    confirmAlert({
      title: 'Smazání notifikace',
      message: `Opravdu si přejete odstranit notifikaci ${notification.nazev}?`,
      buttons: [
        {
          label: 'Smazat',
          onClick: async () => {
            try {
              await apiCall(() => deleteNotification(notification.kod));
              toast.success("Notifikace odstraněna", { theme: "colored" });
              setModalIsOpen(false);
              queryClient.invalidateQueries({ queryKey: [QUERY_NOTIFICATIONS] })
            } catch (e: any) {
              // Není potřeba nic dělat
            }
          }
        },
        {
          label: 'Storno',
        }
      ]
    });
  }

  // TODO duplicita, vytrhnout
  const handleSort = (key: keyof Notifikace) => {
    if (sortConfig == null) {
      setSortConfig({ key: key, direction: 'ascending' });
      return;
    }
    let newSortConfig = { ...sortConfig };
    if (key === sortConfig?.key) {
      newSortConfig.direction = newSortConfig.direction === 'ascending' ? 'descending' : 'ascending';
    }
    newSortConfig.key = key;
    setSortConfig(newSortConfig);
  }

  const renderTable = () => {
    if (!data?.length) {
      return <p>Přidejte první notifikaci tlačítkem "Nová"</p>
    }
    return <Table striped bordered hover>
      <thead>
        <tr>
          <th className={getSortableHeaderClassName(sortConfig, 'kod')} onClick={() => handleSort('kod')}>Kód</th>
          <th className={getSortableHeaderClassName(sortConfig, 'nazev')} onClick={() => handleSort('nazev')}>Název</th>
          <th>Popis</th>
          <th className={getSortableHeaderClassName(sortConfig, 'lhuta')} onClick={() => handleSort('lhuta')}>Lhůta</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {notifikace?.map(notification =>
          <tr key={notification.kod}>
            <td>{notification.kod}</td>
            <td style={{ whiteSpace: 'nowrap' }}>{notification.nazev}</td>
            <td onClick={() => {
              navigator.clipboard.writeText(notification.popis || '');
              toast.success("Text zkopírován do schránky", { theme: "colored", autoClose: 2000 });
            }} style={{ cursor: 'pointer', whiteSpace: 'nowrap', textOverflow: "ellipsis", maxWidth: 800 }}>
              <div title={notification.popis || ''} style={{ maxHeight: '25px', textOverflow: 'ellipsis', overflow: 'clip' }}>
                {notification.popis}
              </div>
            </td>
            <td>{notification.lhuta}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <FontAwesomeIcon icon={faPencil} title='Upravit' className='action-icon' onClick={() => handleEdit(notification)} />
              <FontAwesomeIcon icon={faTrash} title='Odstranit' className='action-icon' onClick={() => handleDelete(notification)} />
            </td>
          </tr>)}
      </tbody>
    </Table>
  }

  // TODO hezčí načítání
  if (isLoading) return <p>Načítám data...</p>

  return <AppPage>
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Notifikace</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Button onClick={handleCreate} style={{ alignSelf: 'flex-end', marginBottom: '20px' }}><FontAwesomeIcon icon={faPlus} /> Nová</Button>
          <p>Počet záznamů: {data?.length}</p>
          {renderTable()}
        </div>
      </div>
      {modalIsOpen && <NotificationModal title={modalTitle} isOpen={modalIsOpen} onClose={handleClose} onSave={handleSave} initialValues={initialValues} />}
    </>
  </AppPage>
}
