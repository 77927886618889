import AppPage from './pages/AppPage';
import { useAuth } from './context/auth';
import { Navigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getClientNotifications } from './api/KlientNotificationsApi';
import { KlientForm, KlientProduktNotifikaceVO, NOTIFICATION_BIRTHDAY_KEY } from './types/Types';
import { QUERY_CLIENTS, QUERY_CLIENT_NOTIFICATIONS, QUERY_NOTIFICATIONS, QUERY_PRODUCTS } from './api/queries';
import { getNotifications } from './api/NotificationsApi';
import { useMemo, useState } from 'react';
import { Klient, Notifikace } from './types';
import { dateToHumanString, stringToDate } from './Utils';
import './App.scss';
import { addEditClient, getClient } from './api/ClientsApi';
import { toast } from 'react-toastify';
import { apiCall } from './api/Api';
import { ClientModal } from './modals/ClientModal';
import { getProducts } from './api/ProductsApi';

function App() {
  const auth = useAuth();

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<KlientForm>();

  const queryClient = useQueryClient();

  const clientNotificationsQuery = useQuery({
    queryKey: [QUERY_CLIENT_NOTIFICATIONS],
    queryFn: getClientNotifications,
  });

  // Číselník produktů
  const productsQuery = useQuery({
    queryKey: [QUERY_PRODUCTS],
    queryFn: getProducts
  });

  const notificationsQuery = useQuery({
    queryKey: [QUERY_NOTIFICATIONS],
    queryFn: getNotifications,
  });

  const notificationsMap = useMemo(() => {
    const result: { [key: string]: Notifikace } = {};
    notificationsQuery?.data?.forEach(notification => {
      result[notification.kod] = notification;
    })
    // Fiktivní záznam pro notifikaci narozenin, server ji záměrně nevrací (nechceme ji nikde jinde)
    result[NOTIFICATION_BIRTHDAY_KEY] = {
      'kod': NOTIFICATION_BIRTHDAY_KEY,
      'lhuta': null,
      'nazev': "Narozeniny",
      'popis': null,
      'poradi': null,
    }
    return result;
  }, [notificationsQuery.data]);

  // TODO celá následující sekce je duplicita z KlientiPage - vykuchat
  const handleClose = () => {
    if (window.confirm("Veškeré neuložené změny budou ztraceny!")) {
      setModalIsOpen(false);
    }
  }

  const handleSave = async (data: Klient, initialValues?: Klient) => {
    try {
      await apiCall(() => addEditClient(data));
      toast.success("Klient uložen", { theme: "colored" });
      setModalIsOpen(false);
      queryClient.invalidateQueries({ queryKey: [QUERY_CLIENTS, QUERY_CLIENT_NOTIFICATIONS] });
      // TODO nevím proč, ale invalidate nestačí, neprovede se sám od sebe refetch
      clientNotificationsQuery.refetch();
    } catch (e: any) {
      // Není potřeba nic dělat
    }
  }

  const handleOpenModal = (data?: KlientForm) => {
    setInitialValues(data);
    setModalIsOpen(true);
  }

  const handleOpenKartaKlienta = async (idKlient?: number | null) => {
    if (idKlient) {
      const client = await getClient(idKlient);
      if (client) {
        handleOpenModal(client);
      }
    }
  }

  const renderNotifikace = (productNotification: KlientProduktNotifikaceVO) => {
    var className = '';
    if (productNotification.kodNotifikace === NOTIFICATION_BIRTHDAY_KEY) {
      className = 'narozeniny';
    } else {
      switch (productNotification.stavNotifikace) {
        case 'NEHOTOVO':
          className = 'nehotovo';
          break;
        case 'ROZPRACOVANO':
          className = 'rozpracovano';
          break;
      }
    }
    if (productNotification.kodNotifikace) {
      const notifikace = notificationsMap[productNotification.kodNotifikace];
      return <td className={className}>{notifikace?.nazev}</td>
    }
  }

  const renderTermin = (productNotification: KlientProduktNotifikaceVO) => {
    if (productNotification.termin) {
      return <td>{dateToHumanString(stringToDate(productNotification.termin))}</td>
    }
  }

  if (!auth?.login) {
    return <Navigate to="/login" replace />;
  }

  const renderTable = () => {
    return <Table striped bordered hover>
      <thead>
        <tr>
          <th>Termín</th>
          <th>Notifikace</th>
          <th>Příjmení</th>
          <th>Jméno</th>
          <th>Zákaznické číslo</th>
          <th>Produkt</th>
          {/* <th></th> */}
        </tr>
      </thead>
      <tbody>
        {/* TODO label pokud není žádná notifikace */}
        {clientNotificationsQuery.data?.map((productNotification, index) =>
          <tr key={index} onClick={() => handleOpenKartaKlienta(productNotification.idKlient)} style={{ cursor: 'pointer' }} title='Kliknutím otevřete kartu klienta'>
            {renderTermin(productNotification)}
            {renderNotifikace(productNotification)}
            <td>{productNotification.prijmeni}</td>
            <td>{productNotification.jmeno}</td>
            <td>{productNotification.zakaznickeCislo}</td>
            <td>{productNotification.produkt}</td>
            {/* <td>
              <FontAwesomeIcon icon={faPencil} title='Karta klienta' className='action-icon' onClick={() => handleKartaKlienta(productNotification.idKlient)} />
            </td> */}
          </tr>
        )}
      </tbody>
    </Table>
  }

  return (
    <AppPage>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h1>Výpis notifikací</h1>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Počet záznamů: {clientNotificationsQuery.data?.length}</p>
          {renderTable()}
        </div>
      </div>
      {/* TODO vyřešit tuhle podivnost */}
      {modalIsOpen ? <ClientModal title="Úprava klienta" isOpen={modalIsOpen} onClose={handleClose} onSave={handleSave} initialValues={initialValues} products={productsQuery.data} notifications={notificationsQuery.data} /> : <span></span>}
    </AppPage>
  );
}

export default App;
