import { Notifikace } from "../types";
import { api } from "./Api";

const BASE_PATH = '/api/notifications';

// Čistě notifikace

export const getNotifications = async () => {
    return await api.get<Notifikace[]>(`${BASE_PATH}/get`);
}

export const addNotification = async (kod: string, nazev: string, popis?: string | null, lhuta?: number | null, poradi?: number | null) => {
    return await api.post<any, any>(`${BASE_PATH}/add`, JSON.stringify({ kod, nazev, popis, lhuta, poradi }));
}

export const editNotification = async (kod: string, nazev: string, popis?: string | null, lhuta?: number | null, poradi?: number | null) => {
    return await api.post<any, any>(`${BASE_PATH}/edit`, JSON.stringify({ kod, nazev, popis, lhuta, poradi }));
}

export const deleteNotification = async (kod: string) => {
    return await api.delete<any>(`${BASE_PATH}/delete?kod=${kod}`);
}