import { ProduktForm } from "../types/Types";
import { api } from "./Api";

const BASE_PATH = '/api/products';

export const getProducts = async () => {
    return await api.get<ProduktForm[]>(`${BASE_PATH}/get`);
}

export const addProduct = async (product: ProduktForm) => {
    return await api.post<any, any>(`${BASE_PATH}/add`, JSON.stringify({ product }));
}

export const editProduct = async (product: ProduktForm, originalName: string) => {
    return await api.post<any, any>(`${BASE_PATH}/edit`, JSON.stringify({ product, originalName }));
}

export const deleteProduct = async (name: string) => {
    return await api.delete<any>(`${BASE_PATH}/delete?name=${name}`);
}
